import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const Layout = ({ isCollapsed, toggleCollapse, logout, children }) => {
  console.log('Layout.js: Rendering...');

  return (
    <div className={`app ${isCollapsed ? 'collapsed' : ''}`}>
      {/* Topbar */}
      <div className="topbar">
        <div className="navbar-logo-container">
          <img src="/primarylogo.png" alt="Logo" />
          <span>Asoba</span>
        </div>
      </div>

      {/* Sidebar Navigation */}
      <nav className="navbar">
        <ul>
          <li className="collapse-icon">
            <button onClick={toggleCollapse} className="collapse-button">
              {isCollapsed ? <FaChevronRight /> : <FaChevronLeft />}
            </button>
          </li>
          <li>
            <NavLink to="/dashboard" className={({ isActive }) => (isActive ? 'activeNav' : '')}>
              Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink to="/on-demand" className={({ isActive }) => (isActive ? 'activeNav' : '')}>
              On-Demand
            </NavLink>
          </li>
          <li>
            <NavLink to="/forecasting" className={({ isActive }) => (isActive ? 'activeNav' : '')}>
              Forecasting
            </NavLink>
          </li>
          <li>
            <NavLink to="/dispatch" className={({ isActive }) => (isActive ? 'activeNav' : '')}>
              Dispatch
            </NavLink>
          </li>
          <li>
            <NavLink to="/settings" className={({ isActive }) => (isActive ? 'activeNav' : '')}>
              Settings
            </NavLink>
          </li>
          <li>
            <NavLink to="/documentation" className={({ isActive }) => (isActive ? 'activeNav' : '')}>
              Documentation
            </NavLink>
          </li>
          <li className="logout-item">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                console.log('Logging out...');
                logout({ returnTo: window.location.origin });
              }}
            >
              Logout
            </a>
          </li>
        </ul>
      </nav>

      {/* Main Content */}
      <div className="main-content">{children}</div>
    </div>
  );
};

export default Layout;

/*
Changes made:
1. Fixed logout click handler to call `logout` with the correct `returnTo` parameter.
2. Added `console.log` for debugging logout events.
*/
