import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUserContext } from './UserContext';

const ProtectedRoute = ({ allowedRoles, children }) => {
  const { userRoles } = useUserContext();

  console.log('ProtectedRoute.js: Validating roles...');
  console.log('userRoles:', userRoles, 'allowedRoles:', allowedRoles);

  if (userRoles.length === 0) {
    console.log('ProtectedRoute.js: Waiting for role validation...');
    return null;
  }

  const hasAccess = allowedRoles.some((role) => userRoles.includes(role));
  if (!hasAccess) {
    console.log('ProtectedRoute.js: Unauthorized. Redirecting to /unauthorized...');
    return <Navigate to="/unauthorized" replace />;
  }

  console.log('ProtectedRoute.js: Authorized. Rendering child components...');
  return children;
};

export default ProtectedRoute;
