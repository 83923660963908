import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const [userRoles, setUserRoles] = useState([]);
  const [isFetchingRoles, setIsFetchingRoles] = useState(true);

  useEffect(() => {
    const fetchRoles = async () => {
      if (isAuthenticated) {
        const claims = await getIdTokenClaims();
        const roles = claims['https://asobahost.com/roles'] || [];
        setUserRoles(roles);
      }
      setIsFetchingRoles(false);
    };

    fetchRoles();
  }, [isAuthenticated, getIdTokenClaims]);

  return (
    <UserContext.Provider value={{ userRoles, isFetchingRoles }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
