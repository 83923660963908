import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

function FormComponent({ onResponse }) {
  const [apiKey, setApiKey] = useState('');
  const [file, setFile] = useState(null);
  const [customerId, setCustomerId] = useState('');
  const [oemName, setOemName] = useState('lux');

  // Callback for when a file is selected or dropped
  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.csv',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      alert('Please upload a file before submitting');
      return;
    }

    if (!apiKey) {
      alert('Please provide an API key.');
      return;
    }

    const url = `https://yn058ezh38.execute-api.af-south-1.amazonaws.com/test/upload_historical?customer_id=${customerId}&filename=${file.name}&manufacturer=${oemName}`;

    try {
      const response = await axios({
        method: 'post',
        url: url,
        data: file, // Send file directly, as binary data
        headers: {
          'x-api-key': apiKey,
          'Content-Type': 'text/csv', // Explicitly setting Content-Type to text/csv
          'Accept': '*/*',
        },
        responseType: 'json',
      });
      onResponse(response.data);
    } catch (error) {
      onResponse(error.response ? error.response.data : error.message);
    }
  };

  return (
    <div className="form-container"> {/* Added consistent container */}
      <form onSubmit={handleSubmit}>
        <label htmlFor="apiKey">API Key:</label>
        <input
          type="text"
          id="apiKey"
          name="apiKey"
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
          placeholder="Enter your API key"
        />

        <label htmlFor="customerId">Customer ID:</label>
        <input
          type="text"
          id="customerId"
          name="customerId"
          value={customerId}
          onChange={(e) => setCustomerId(e.target.value)}
        />

        <label htmlFor="oemName">Device Manufacturer:</label>
        <select
          id="oemName"
          name="oemName"
          value={oemName}
          onChange={(e) => setOemName(e.target.value)}
        >
          <option value="lux">Lux</option>
          <option value="macrocomm">Macrocomm</option>
        </select>

        <label htmlFor="uploadFile">Upload File:</label>
        <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
          <input {...getInputProps()} id="uploadFile" name="uploadFile" />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag 'n' drop some files here, or click to select files</p>
          )}
        </div>

        {file && <p>Selected file: {file.name}</p>}

        <button className="submit-button" type="submit">Submit</button>
      </form>
    </div>
  );
}

export default FormComponent;
