import React, { useState, useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useUserContext } from './components/UserContext';
import Layout from './components/Layout';
import Dashboard from './components/Dashboard';
import OnDemand from './components/OnDemand';
import Forecasting from './components/Forecasting';
import Dispatch from './components/Dispatch';
import Settings from './components/Settings';
import Login from './components/Login';
import Documentation from './components/Documentation';
import ProtectedRoute from './components/ProtectedRoute';
import LoadingScreen from './components/LoadingScreen'; // Correct component
import './App.css';

const App = () => {
  console.log('App.js: Rendering...');

  const { isAuthenticated, isLoading: authLoading, logout } = useAuth0();
  const { isFetchingRoles, userRoles } = useUserContext();
  const [showLoadingScreen, setShowLoadingScreen] = useState(true); // Sticky state for loading screen

  // Consolidate all conditions into a single loading state
  const isAppLoading = useMemo(
    () => authLoading || isFetchingRoles || (isAuthenticated && userRoles.length === 0),
    [authLoading, isFetchingRoles, isAuthenticated, userRoles]
  );

  // Effect to manage the sticky loading screen state
  useEffect(() => {
    if (!isAppLoading) {
      const timer = setTimeout(() => setShowLoadingScreen(false), 300); // Smooth transition delay
      return () => clearTimeout(timer);
    } else {
      setShowLoadingScreen(true); // Show loading during transitions
    }
  }, [isAppLoading]);

  const layoutProps = useMemo(() => ({
    isCollapsed: false,
    toggleCollapse: () => {},
    logout: () => 
      logout({ 
        returnTo:window.location.origin, }),
  }), [logout]);

  console.log('App.js: Validating auth and roles...');
  console.log('isAuthenticated:', isAuthenticated);
  console.log('authLoading:', authLoading);
  console.log('isFetchingRoles:', isFetchingRoles);
  console.log('userRoles:', userRoles);

  return (
    <Router>
      {/* Full-page loading screen */}
      {showLoadingScreen && <LoadingScreen />}
      <Routes>
        <Route
          path="/"
          element={isAuthenticated ? <Navigate to="/dashboard" replace /> : <Login />}
        />
        <Route
          path="/*"
          element={
            <Layout {...layoutProps}>
              <Routes>
                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute allowedRoles={['demandforecast-beta', 'admin']}>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/on-demand"
                  element={
                    <ProtectedRoute allowedRoles={['ondemand-alpha', 'admin']}>
                      <OnDemand />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/forecasting"
                  element={
                    <ProtectedRoute allowedRoles={['demandforecast-beta', 'admin']}>
                      <Forecasting />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/dispatch"
                  element={
                    <ProtectedRoute allowedRoles={['demandforecast-beta', 'admin']}>
                      <Dispatch />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <ProtectedRoute allowedRoles={['ondemand-alpha', 'admin', 'demandforecast-beta']}>
                      <Settings />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/documentation"
                  element={
                    <ProtectedRoute allowedRoles={['ondemand-alpha', 'admin', 'demandforecast-beta']}>
                      <Documentation />
                    </ProtectedRoute>
                  }
                />
                <Route path="*" element={<Dashboard />} />
              </Routes>
            </Layout>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;

/*
Changes:
1. Updated logout to send to the current base url returnTo:window.location.origin,
*/
